/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:6ac2b005-5c5d-4c74-afec-a36f2e3ee2ab",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_SqfyqN4lu",
    "aws_user_pools_web_client_id": "21mamc3hnki53e336vmn04gj48",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "jomu-instrument-store",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
